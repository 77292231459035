.news {
    background-color: #112C54;
    padding-top: 1%;
    padding-bottom: 2%;
}

.news-thumbnail-container {
    display: flex;
    justify-content: space-evenly;
}
  
.news-thumbnail {
    position: relative;
    max-width: 600px;
    text-align: left;
    display: inline-block;
    margin-right: 12px;
    margin-left: 12px;
    border: 2px solid black;
    transition: border 0.25s;
    margin-top: 16px;
    margin-bottom: 16px;
}
  
.news-thumbnail:hover {
    border: 2px solid white;
}
  
.news-thumbnail img {
    max-width: 100%;
}
  
.news-thumbnail a {
    color: white;
}
  
.news-thumbnail-overlay {
    position: absolute;
    background: linear-gradient(transparent, black);
    bottom: 0;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
  
.news-thumbnail-overlay-date {
    font-size: 10pt;
    padding-left: 8px;
    width: 80%;
}
  
.news-thumbnail-overlay-title {
    font-size: 16pt;
    padding-left: 8px;
    padding-bottom: 8px;
    font-weight: bold;
    width: 80%;
}
  
.news-thumbnail-icon {
    position: absolute;
    width: 8%;
    margin-bottom: 1%;
    margin-right: 1%;
    bottom: 0;
    right: 0;
    opacity: 0.75;
    padding: 4px;
    filter: drop-shadow(0px 0px 8px black);
}