.contact {
    background: linear-gradient(#071222, #02070c);
    padding-top: 1%;
    padding-bottom: 2%;
    width: 100%;
    text-align: center;
}
  
.contact-item {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: 12px;
    display: block;
    font-size: 12pt;
}
  
.contact-item .contact-item-title {
    display: inline-block;
    width: 50%;
    font-weight: bold;
}
  
.contact-item .contact-item-value {
    text-align: right;
    display: inline-block;
    width: 50%;
}
  
.contact-socials {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    display: block;
}
  
.contact-socials-button {
    padding: 18px 12px 10px 12px;
    margin: 8px;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    color: white;
}

@media (max-width: 1000px) {
    .contact-item {
      font-size: 10pt;
      width: 60%;
    }
  }

@media (max-width: 750px) {
    .contact-item {
      font-size: 8pt;
      width: 80%;
    }
  }