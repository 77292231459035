body {
    color: white;
    background: rgb(18,47,89);
}

.article-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
}

#article-thumbnail {
    border: 2px black solid;
    max-width: 100%;
}

#article-date {
    text-align: left;
    font-size: 12pt;
    display: inline-block;
    margin-left: 2px;
    margin-top: 2px;
}

#article-icon {
    position: relative;
    display: inline-block;
    width: 20pt;
    margin-right: 4px;
}

#article-icon img {
    position: absolute;
    bottom: -8px;
    width: 100%;
}

#article-title {
    font-size: 32pt;
    text-align: center;
    display: inline-block;
}

#article-content {
    text-align: left;
    font-size: 14pt;
    border-top: 1px solid white;
}

@media (max-width: 1000px) {
    #article-date {
        font-size: 10pt;
    }

    #article-icon {
        width: 16pt;
    }

    #article-icon img {
        bottom: -6px;
    }
    
    #article-title {
        font-size: 18pt;
    }
    
    #article-content {
        font-size: 12pt;
    }
}

@media (max-width: 750px) {
    #article-date {
        font-size: 8pt;
    }

    #article-icon {
        width: 14pt;
    }

    #article-icon img {
        bottom: -6px;
    }
    
    #article-title {
        font-size: 14pt;
    }
    
    #article-content {
        font-size: 10pt;
    }
}