.article-list {
    text-align: center;
}

.article-list h1 {
    display: inline-block;
}

.article-item {
    display: flex;
    text-align: left;
    padding: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    background: linear-gradient(0deg, rgb(14, 36, 68) 0%, rgb(20, 55, 104) 100%);
    transition: border 0.25s;
}

.article-item:hover {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.article-thumbnail {
    border: 2px solid black;
    display: block;
    max-width: 400px;
    transition: border 0.25s;
}

.article-thumbnail img {
    max-width: 100%;
    display: block;
}

.article-item:hover > .article-thumbnail {
    border: 2px solid white;
}

.article-content {
    color: white;
    display: block;
    margin-left: 16px;
    min-width: 250px;
}

.article-title {
    position: relative;
    font-size: 32pt;
    display: block;
    font-weight: bold;
}

.article-icon {
    position: relative;
    display: inline-block;
    width: 20pt;
    margin-right: 4px;
}

.article-icon img {
    position: absolute;
    bottom: -8px;
    width: 100%;
}

.article-date {
    display: inline-block;
    font-size: 10pt;
}

.article-summary {
    display: block;
    font-size: 16pt;
}
@media (max-width: 1000px) {
    .article-content {
        margin-left: 8px;
    }

    .article-title {
        font-size: 20pt;
    }

    .article-icon {
        width: 18pt;
        margin-right: 4px;
    }

    .article-date {
        font-size: 8pt;
    }

    .article-summary {
        display: block;
        font-size: 12pt;
    }
}

@media (max-width: 750px) {
    .article-content {
        margin-left: 4px;
    }

    .article-title {
        font-size: 16pt;
    }

    .article-icon {
        width: 12pt;
        margin-right: 2px;
    }

    .article-icon img {
        bottom: -4px;
    }

    .article-date {
        font-size: 7pt;
    }

    .article-summary {
        display: block;
        font-size: 8pt;
    }
}