.description {
    background-color: #131313;
    padding-top: 1%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-evenly;
    box-shadow: 0px 0px 25px 0px black inset;
}
  
.description-item {
    padding-left: 128px;
    padding-right: 128px;
    min-width: 30%;
}

@media (max-width: 1000px) {
    .description {
      flex-direction: column;
    }
  
    .description-item {
        padding-left: 64px;
        padding-right: 64px;
        min-width: 80%;
    }
}

@media (max-width: 750px) {
    .description {
      flex-direction: column;
      font-size: 10pt;
    }
  
    .description-item {
        padding-left: 16px;
        padding-right: 16px;
        min-width: 90%;
    }

    .description h1 {
        font-size: 16pt;
    }

    .description h3 {
        font-size: 12pt;
    }
}