.games {
    background-color: #112C54;
    padding-top: 1%;
    padding-bottom: 2%;
    text-align: center;
}
  
.game-container {
    display: inline-block;
    text-align: center;
    position: relative;
    border: 2px solid black;
    margin-left: 12px;
    margin-right: 12px;
    transition: border 0.25s;
    max-width: 1000px;
}
  
.game-container:hover {
    border: 2px solid white;
}
  
.game-container img {
    max-width: 100%;
    display: block;
}
  
.game-logo {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    transition: opacity 0.25s;
}
  
.game-logo img {
    display: inline-block;
}
  
.game-container:hover > .game-logo {
    opacity: 0.5;
}
  
.game-container:hover > #game-clip {
    opacity: 1;
}

#game-clip {
    position: absolute;
    left: 0;
    top: 0;
    max-height: 100%;
    opacity: 0;
    transition: opacity 0.25s;
}