.header {
    background-color: #112C54;
    padding-top: 1%;
    padding-bottom: 2%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    text-align: center;
  }
  
  .header img {
    filter: drop-shadow(0px 0px 16px black);
    max-width: 50%;
  }