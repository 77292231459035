#container {
    background-image: url("background.png");
    background-repeat: repeat;
    text-align: center;
    width: 100%;
    min-height: 100%;
}

#logo {
    filter: drop-shadow(0px 0px 8px black);
    max-width: 80%;
}

@media (max-width: 1000px) {
    #container h2 {
        font-size: 16pt;
    }

    #container h3 {
        font-size: 12pt;
    }
}

@media (max-width: 750px) {
    #container h2 {
        font-size: 12pt;
    }

    #container h3 {
        font-size: 8pt;
    }
}