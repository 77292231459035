body {
    background-color: #071222;
    color: white;
}
  
.App {
    text-align: center;
}
  
h1 {
    filter: drop-shadow(0px 0px 8px black);
}
  
.divider {
    width: 80%;
    border-top: 1px solid black;
    display: block;
    margin-left: auto;
    margin-right: auto;
}