#home-button {
    position: fixed;
    top: 0;
    left: 0;
    padding: 8px 8px 6px 8px;
    margin: 8px;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    color: white;
}
  
#home-button:hover {
    color: rgb(78, 119, 255);
}
  
a {
    color: rgb(49, 97, 255);
    transition: color 0.25s;
    text-decoration: none;
}
  
a:hover {
    color: rgb(111, 145, 255);
}

.link-button {
    background: rgba(0, 0, 0, 0.25);
    padding: 8px;
    display: block;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    color: white;
    border: 2px solid transparent;
    transition: border 0.25s;
}
  
.link-button:hover {
    border: 2px solid white;
    color: white;
}